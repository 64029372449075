<template>
    <div>
        <b-overlay :show="saving">
            <div class="row" v-if="showSearch()">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <vue-bootstrap-autocomplete
                                size="lg"
                                v-model="selectedClient"
                                noResultsInfo="No matching members found"
                                placeholder="Search for a member here"
                                @hit="selectClient()"
                                :data="clientsComputed"
                            >
                            </vue-bootstrap-autocomplete>
                        </div>
                    </div>
                </div>
            </div>
            <CreateOrdersViewWizard v-if="clienIdInUrl && orderOn" :client_data="clientData" @busySaving="busySaving" />
        </b-overlay>
    </div>
</template>

<script>
import CreateOrdersViewWizard from "./createOrdersViewWizard.vue";
import { orderMethods, orderComputed, adminComputed, clientComputed, clientMethods } from "@/state/helpers";
import _ from "lodash";

export default {
    components: {
        CreateOrdersViewWizard,
    },
    data: () => ({
        saving: false,
        clientData: "",
        clienIdInUrl: false,
        orderOn: false,
        selectedClient: "",
    }),
    computed: {
        ...adminComputed,
        ...orderComputed,
        ...clientComputed,

        clientsComputed() {
            var clients = [];
            var client;
            _.forEach(this.clients, function (value) {
                client = value.member_name + " - " + value.emails;
                clients.push(client);
            });
            return clients;
        },
    },
    methods: {
        ...orderMethods,
        ...clientMethods,
        busySaving(flag) {
            this.saving = flag;
        },
        showSearch() {
            if (this.user_role == "admin") {
                return true;
            }

            return false;
        },
        selectClient() {
            this.orderOn = false;
            this.$nextTick(() => {
                var clientName = this.selectedClient.split("-");
                clientName = clientName[0].trim();
                var findIndex = _.findIndex(this.clients, function (o) {
                    return o.member_name == clientName;
                });
                if (findIndex > -1) {
                    this.clientData = {
                        address: this.clients[findIndex].address,
                        company_name: this.clients[findIndex].company_name,
                        company_reg: this.clients[findIndex].company_reg,
                        contact_no: this.clients[findIndex].contact_no,
                        contact_person: this.clients[findIndex].contact_person,
                        customer_no: this.clients[findIndex].customer_no,
                        emails: this.clients[findIndex].emails,
                        id: this.clients[findIndex].id,
                        province: this.clients[findIndex].province,
                        city: this.clients[findIndex].city,
                        postal_code: this.clients[findIndex].postal_code,
                    };
                    this.orderOn = true;
                    this.clienIdInUrl = true;
                }
                //eslint-disable-next-line
                //if the inputs data changes psuh client id to the url
                this.$router.push(`/create-order/${this.clientData.id}`);
            });
        },
        closeModal() {
            this.$bvModal.hide("ordersModal");
            this.getBranchOrders(this.selected_branch_id).then(() => {
                this.orderOn = false;
            });
        },
    },
    mounted() {
        //eslint-disable-next-line
        let client_id = this.$route.params.clientid;
        //eslint-disable-next-line

        if (client_id === undefined) {
            this.clienIdInUrl == false;
        } else {
            this.clienIdInUrl = true;
            this.orderOn = true;
        }

        // if (client_id) {
        //     this.setSelectedClientDetails(client_id);
        // }

        //necesasry so that clientData can be passed as a prop
        this.clientData = this.selected_client_details;

        //eslint-disable-next-line
        // console.log("client data", this.clientData, this.selected_client_details);

        //first load: will populate the input
        if (this.$route.params.clientid != undefined && this.loggedIn_user_role !== 1) {
            this.selectedClient = `${this.selected_client_details.company_name} - ${this.selected_client_details.emails}`;
        }
    },
};
</script>

<style lang="scss" scoped></style>
